import flightAirlines from "@/lib/data-service/flight/flight_domestic_airlines";
import airborneList from "@/lib/data-service/flight/flight_domestic_buyer_manage_order_airborneList";
import scrollTop from "@/lib/common-service/scrollTop";
import AirlineSelect from "@/page/distributor/air-ticket/components/airlineSelect.vue";
import buyer_dom_manage_AirChangeList from '@/lib/data-service/flight/buyer_dom_manage_AirChangeList'
import plat_dom_manage_getAirChangeTypeEnum from '@/lib/data-service/flight/plat_dom_manage_getAirChangeTypeEnum'
import plat_dom_manage_AirChangeAffirm from '@/lib/data-service/flight/plat_dom_manage_AirChangeAffirm'
import moment from "moment";
import Pagination from '@/component/base-pagination/index.vue'
import RouterLinkButton from 'yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/router-link-button/1.0.1/index.vue'
export default {
    data() {
        return {
            searchForm: {
                airborneTimeEnd: '',
                airborneTimeStart: '', // 航变日期-开始
                airborneTime: [],
                changeType: '',
                flightNo: '',
                notifyStatus: '',
                oldBeginTimeDate: '', // 原起飞时间-开始
                oldBeginTimeEnd: '',
                oldBeginTime: [],
                orderNo: '',
                pnr: '',
                currentPage: 1,
                pageSize: 10,
                total: 0,
            },
            list: [],
            tableData: [],
            loading: false,
            airlineList: [], //航线公司
            options: [],
            notifyStatusOptions: [
                // 状态 1-未通知 2-已通知
                {value: '', label: '全部'},
                {value: 1, label: '未通知'},
                {value: 2, label: '已通知'},
            ],
        }
    },
    components: {AirlineSelect, Pagination, RouterLinkButton},
    created() {
    },
    mounted() {
        this.getAirChangeTypeEnum();
        this.getList();
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {
        filterDay(val) {
            if (!val) {
                return ''
            } else {
                return moment(val).format('YYYY-MM-DD')
            }
        },
        filterTime(val) {
            if (!val) {
                return ''
            } else {
                return moment(val).format('HH:mm')
            }
        },
    },
    methods: {
        changeOrderDate(val) {
            if (val === '' || val === null) {
                this.searchForm.airborneTimeStart = ''
                this.searchForm.airborneTimeEnd = ''
            } else {
                this.searchForm.airborneTimeStart = val[0]
                this.searchForm.airborneTimeEnd = val[1]
            }
        },
        changeOrderBeginDate(val) {
            if (val === '' || val === null) {
                this.searchForm.oldBeginTimeDate = ''
                this.searchForm.oldBeginTimeEnd = ''
            } else {
                this.searchForm.oldBeginTimeDate = val[0]
                this.searchForm.oldBeginTimeEnd = val[1]
            }
        },
        reset() {
            this.searchForm = {
                airborneTimeEnd: '',
                airborneTimeStart: '', // 航变日期-开始
                airborneTime: [],
                changeType: '',
                flightNo: '',
                notifyStatus: '',
                oldBeginTimeDate: '', // 原起飞时间-开始
                oldBeginTimeEnd: '',
                oldBeginTime: [],
                orderNo: '',
                pnr: '',
                currentPage: 1,
                pageSize: 10,
                total: 0,
            };
            this.getList();
        },
        getList() {
            this.loading = true;
            let data = this.searchForm;
            buyer_dom_manage_AirChangeList(data).then(res => {
                this.loading = false;
                this.tableData = res.pageResult.pageData;
                this.searchForm.total = res.pageResult.totalCount;
                scrollTop(500);
            }).catch(() => {
                this.loading = false
            });
        },
        getAirChangeTypeEnum() {
            plat_dom_manage_getAirChangeTypeEnum().then(res => {
                let aerialChangeList = res.res
                aerialChangeList.unshift({
                    value: '',
                    msg: '全部'
                })
                this.options = aerialChangeList
            })
        },
        toSubmit(val) {
            if (val.loading) {
                return
            }
            this.$confirm('是否确认？', '温馨提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                val.loading = true
                plat_dom_manage_AirChangeAffirm({id: val.id}).then(res => {
                    val.loading = false
                    this.$message({
                        type: "success",
                        message: '确认成功'
                    })
                    this.getOrderList()
                })
            }).catch(() => {

            });
        },
        toOrderDetail(val) {
            /*this.$router.push({
                name: 'air-ticket-admin-airDetails',
                query: {
                    ordersNo: val.orderNo,
                    psgType: 1,
                }
            })*/
            return {
                name: 'air-ticket-admin-airDetails',
                query: {
                    ordersNo: val.orderNo,
                    psgType: 1,
                }
            }
        },
    }
}
// plat_dom_manage_AirChangeAffirm
const __request = require(`./__request/flight-admin/__request_contentType_json`);

export default function plat_dom_manage_AirChangeAffirm(pParameter) {
    if (!pParameter) pParameter = {};
    // const airborneTime = pParameter.airborneTime || ''
    // const currentPage = pParameter.currentPage || 1
    // const flightNo = pParameter.flightNo || ''
    // const oldArriveCityName = pParameter.oldArriveCityName || ''
    // const oldBeginCityName = pParameter.oldBeginCityName || ''
    // const pageSize = pParameter.pageSize || 10
    let params = {}
    params.method = 'POST'
    params.urlSuffix = '/plat/dom/manage/AirChangeAffirm'
    params.data = pParameter
    return new Promise(function(resolve, reject) {
        resolve(__request(params))
    })
}

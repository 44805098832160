// plat_dom_manage_getAirChangeTypeEnum
const __request = require(`./__request/flight-admin/__request_contentType_json`);

// 接口地址： http://192.168.0.31:8985/swagger-ui.html#/platform-controller/cancelChangePayUsingPOST_1

export default function plat_dom_manage_getAirChangeTypeEnum(pParameter) {
    if (!pParameter) pParameter = {};
    let params = {}
    params.method = 'POST'
    params.urlSuffix = '/plat/dom/manage/getAirChangeTypeEnum'
    params.data = pParameter
    return new Promise(function(resolve, reject) {
        resolve(__request(params))
    })
}
